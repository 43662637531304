(function() {
    angular.module('EntrakV5').controller('cityController', cityController);

    function cityController($scope, $rootScope, Service, Api, APIKEY, KEY, $state) {
        console.log('cityController', $state.current.name);

        var caller = Api.createApiCaller();
        $scope.btnStatus = {};

        $scope.cities = null;

    /* first load */
        $rootScope.loadingPage = 1;
        caller.call(Api.getFullCities()).then(function(res){
            res.forEach(processCity);
            $scope.cities = res;
            $rootScope.loadingPage--;
        }, function(err){
            if (err === KEY.ignore)
                return;
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
        });

        function processCity(city){
            city.forSort = city.country + city.city;
        }
    /* first load */

    /* edit */
        $scope.editCityData = {};
        $scope.editCityWinOpt = {
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.providerDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                name: Service.translate("city." +　APIKEY.weatherProvider.open),
                value: APIKEY.weatherProvider.open
            }, {
                name: Service.translate("city." +　APIKEY.weatherProvider.hk),
                value: APIKEY.weatherProvider.hk
            }, {
                name: Service.translate("city." +　APIKEY.weatherProvider.sg),
                value: APIKEY.weatherProvider.sg
            }],
            dataTextField: "name",
            dataValueField: "value"
        }

        $scope.confirmEditCity = function(){
            var isNew = !$scope.editCityData.id;
            $scope.btnStatus.saving = true;
            if (isNew){
                var promise = Api.createCity($scope.editCityData.country, $scope.editCityData.city, $scope.editCityData.timezone, $scope.editCityData.provider, $scope.editCityData.locationCode, $scope.editCityData.holidayCode);
            } else {
                var promise = Api.updateCity($scope.editCityData.id, $scope.editCityData.country, $scope.editCityData.city, $scope.editCityData.timezone, $scope.editCityData.provider, $scope.editCityData.locationCode, $scope.editCityData.holidayCode);
            }
            caller.call(promise).then(function(res){
                processCity(res);
                Service.replaceArrItem($scope.cities, res, isNew);
                $scope.btnStatus.saving = false;
                $scope.editCityWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                if (err.graphQLErrors && (err = err.graphQLErrors[0])){
                    if (err.message.indexOf("timezone value must be a valid timezone") != -1){
                        alert(Service.translate("error.invalidTimezone"));
                        return;
                    }
                }
                alert(Service.translate(isNew ? "error.generalCreateCity" : "error.generalUpdateCity"));
            });
        }
        $scope.editCity = function(city){
            if (city){
                $scope.editCityWin.title(Service.translate("city.popup.editCity"));
                $scope.editCityData.id = city.id;
                $scope.editCityData.city = city.city;
                $scope.editCityData.country = city.country;
                $scope.editCityData.timezone = city.timezone;
                $scope.editCityData.provider = city.provider;
                $scope.editCityData.holidayCode = city.holidayCode;
                $scope.editCityData.locationCode = city.locationCode;
            } else {
                $scope.editCityWin.title(Service.translate("city.popup.addCity"));
                $scope.editCityData = {
                    provider: APIKEY.weatherProvider.open
                }
            }
            setTimeout(function(){
                $scope.editCityWin.center().open();
            });
        }

        $scope.deleteCity = function(){
            $scope.editCityWin.close();
            var id = $scope.editCityData.id;
            $rootScope.deletePopup.show("city.popup.deleteCity", "city.popup.deleteCityDesc", id, function(){
                caller.call(Api.deleteCity(id)).then(function(res){
                    Service.deleteArrItem($scope.cities, id);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    alert(Service.translate("error.generalDeleteFail"));
                });
            });
        }
    /* edit */

        $scope.$on('$destroy', function() {
            console.log("cityController destroy");
            caller.cancel();
        });
    }
})();